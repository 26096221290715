import React from 'react';
import { Link } from 'gatsby';
import logo from "../../assets/images/logo.png";

const Navbar = () => {
    return (

                    <Link to="/" >
                        <img src={logo} alt="Rutherford Labs Logo" />
                    </Link>


    );
}

export default Navbar;
